import React from 'react';
import {
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import LocaleRouteProxy from "./components/LocaleRouteProxy";
import {fallbackLocale} from "./i18n/resources";

export default function App() {
    return <Switch>
        <Route
            path="/:lang/"
            component={LocaleRouteProxy}
        />
        <Route path="/" render={() => <Redirect to={`/${fallbackLocale}/`}/>}/>
    </Switch>
}
