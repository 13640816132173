import React from 'react';
import Layout from "../components/Layout";
import {Link} from "react-router-dom";
import fish2Img from '../images/img-fish2.png';

export default function NotFound() {
    return <Layout>
        <div className="container text-center">
            <div className="error-page">
                <h1>404 Page introuvable</h1>
                <p>Désolé, la page que vous recherchez s'est perdue dans les océans.</p>
                <img
                    className={'img-fluid'}
                    src={fish2Img} alt=""/>
                <p>
                    <Link
                        className={'btn btn-danger'}
                        to={'/'}>Retour à la surface</Link>
                </p>
            </div>
        </div>
    </Layout>
}
