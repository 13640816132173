import React, {useEffect, useState} from "react";
import {getStyleObjectFromString} from "../styleUtils";
import OctaveImg from '../images/octave.png';
import zipIcon from '../images/icon-zip.svg';
import jpgIcon from '../images/icon-jpg.svg';
import docIcon from '../images/icon-doc.svg';
import pngIcon from '../images/icon-png.svg';
import xlsIcon from '../images/icon-xls.svg';
import pdfIcon from '../images/icon-pdf.svg';
import BoxIcon from '../images/icon-box.png';
import txtIcon from '../images/icon-txt.svg';
import DropboxIcon from '../images/icon-dropbox.png';
import ICloudIcon from '../images/icon-icloud.png';
import GDriveIcon from '../images/logo-googledrive@1x.png';
import OneDriveIcon from '../images/logo-onedrive@1x.png';
import submarineImg from '../images/submarine.png';
import plant1Img from '../images/img-plant1.png';
import plant2Img from '../images/img-plant2.png';
import plant3Img from '../images/img-plant3.png';
import fish1Img from '../images/img-fish1.png';
import fish2Img from '../images/img-fish2.png';
import fish3Img from '../images/img-fish3.png';
import fish4Img from '../images/img-fish4.png';
import PFictionImg from '../images/poulpe-fiction.svg';
import rgpdImg from '../images/logo-rdpg.png';
import iphoneImg from '../images/iphone.png';
import facebookImg from '../images/social-facebook.svg';
import githubImg from '../images/social-github.svg';
import instagramImg from '../images/social-instagram.svg';
import linkedinImg from '../images/social-linkedin.svg';
import twitterImg from '../images/social-twitter.svg';
import {initScrollMagic} from "../lib/anim";
import LandingEmailForm from "../components/LandingEmailForm";
import {Carousel} from 'react-responsive-carousel';
import Navbar from "../components/Navbar";
import {Trans} from "react-i18next";
import Footer from "../components/Footer";
import ChatThread from "../components/ChatThread";

export default function LandingProPage() {
    useEffect(() => {
        return initScrollMagic();
    }, []);

    const [landingSubmitted, setLandingSubmitted] = useState(false);
    const [email, setEmail] = useState<string>('');

    const landingForm = <LandingEmailForm
        onSubmit={() => setLandingSubmitted(true)}
        submitted={landingSubmitted}
        email={email}
        setEmail={setEmail}
        pro={true}
    />

    return <>
        <section id="intro" className={'first'}>
            <div className="bg">
                <div className="container">
                    <Navbar rootUrl={'/pro'}/>

                    <div className="row">
                        <div className="col col-md-3">
                            <img id="main-octave"
                                 className="push-sm-bottom img-fluid mx-auto d-block"
                                 alt={''}
                                 src={OctaveImg}/>
                        </div>
                        <div className="col-md">
                            <p className="hook">
                                <Trans i18nKey="landing_pro.intro">
                                    Enchanté de faire votre connaissance,
                                    <br/>
                                    Je suis Octave, <b>l'assistant administratif</b> de <b>toute</b> votre entreprise
                                </Trans>
                            </p>

                            <div className="slider">
                                <Carousel
                                    showArrows={false}
                                    showStatus={false}
                                    showThumbs={false}
                                    emulateTouch={true}
                                    autoPlay={true}
                                    interval={7000}
                                    renderItem={(item, options) => {
                                        return <div
                                            className={`chat ${options?.isSelected || options?.isPrevious ? 'chat-active' : ''}`}>
                                            {item}
                                        </div>
                                    }}
                                >
                                    <ChatThread
                                        messages={[
                                            {
                                                from: 'marie',
                                                message: <Trans i18nKey="landing_pro.thread1.1">
                                                    Hey Octave, partage mon RIB avec Lancelot
                                                </Trans>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing_pro.thread1.2">
                                                    Très bien, je m'en charge.
                                                </Trans>
                                            },
                                            {
                                                from: 'dad',
                                                message: <Trans i18nKey="landing_pro.thread1.3">
                                                    Bien reçu !
                                                </Trans>
                                            },
                                        ]}
                                    />
                                    <ChatThread
                                        messages={[
                                            {
                                                from: 'marie',
                                                message: <div className="uploaded-file">
                                                    <img className="f-icon" src={pdfIcon} alt="pdf"/>
                                                    <span className="f-filename">
                                                        <Trans i18nKey="landing_pro.thread2.1">
                                                        facture-fournisseur.pdf
                                                        </Trans>
                                                    </span>
                                                </div>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing_pro.thread2.2">
                                                    J'ai bien enregistré votre document
                                                </Trans>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing_pro.thread2.3">
                                                    J'ai bien reconnu <b>Facture Fournisseur Anémone Industries Juin 2020</b>.
                                                </Trans>
                                            },
                                        ]}
                                    />

                                    <ChatThread
                                        messages={[
                                            {
                                                from: 'marie',
                                                message: <Trans i18nKey="landing_pro.thread3.1">
                                                    Combien de jours de congé me reste-t-il ?
                                                </Trans>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing_pro.thread3.2">
                                                    D'après votre dernier bulletin de salaire,
                                                    il vous reste <b>8</b> jours de congé et <b>2</b> jours de RTT.
                                                </Trans>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing_pro.thread3.3">
                                                    Souhaitez-vous faire une demande de congé à votre manager ?
                                                </Trans>
                                            },
                                        ]}
                                    />

                                    <ChatThread
                                        messages={[
                                            {
                                                from: 'antoine',
                                                message: <Trans i18nKey="landing_pro.thread4.1">
                                                    Je réserve les billets d'avion pour le Salon <span className="highlight">Xpo 2022</span>,
                                                    peux-tu me donner ton numéro de passeport ?
                                                </Trans>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing_pro.thread4.2">
                                                    Je partage votre numéro de passeport <span className="highlight">numéro de passeport</span> avec <span className="highlight">Antoine</span> ?
                                                </Trans>
                                            },
                                            {
                                                from: 'marie',
                                                message: <Trans i18nKey="landing_pro.thread4.3">
                                                    Oui
                                                </Trans>
                                            },
                                        ]}
                                    />

                                    <ChatThread
                                        messages={[
                                            {
                                                from: 'marie',
                                                message: <Trans i18nKey="landing_pro.thread5.1">
                                                    Créer un dossier de réponse à appel d'offre
                                                </Trans>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing_pro.thread5.2">
                                                    Il me manque un Kbis de moins de 3 mois.
                                                    Souhaitez-vous que j'en télécharge un régulièrement pour vous ?
                                                </Trans>
                                            },
                                        ]}
                                    />
                                </Carousel>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="backscene">
                <div className="container">
                    <img className="px" id="a-intro-1"
                         style={{
                             bottom: -50,
                             zoom: '47%',
                             transform: 'scaleX(-1)',
                         }}
                         src={OctaveImg}
                         alt={''}/>
                    <img className="px" id="a-intro-2"
                         style={{
                             top: 446,
                             right: -150,
                             zoom: '25%',
                             transform: 'rotateZ(276deg)',
                         }}
                         src={OctaveImg}
                         alt={''}/>
                    <img className="px" id="a-intro-3"
                         style={{
                             bottom: 10,
                             right: -50,
                             zoom: '80%',
                             transform: 'scaleX(-1) rotateZ(-25deg)',
                         }}
                         src={OctaveImg}
                         alt={''}/>
                </div>
            </div>
        </section>
        <section id="pro">
            <div className="bg">
                <div className="container">
                    <p className="hook hook-center">
                        <Trans i18nKey="landing_pro.pro.intro">
                            Grâce à ce que vous m'apprennez,
                            <br/>j'automatise vos tâches administratives
                        </Trans>
                    </p>
                    <div className="chat chat-center">
                        <ChatThread
                            messages={[
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing_pro.thread6.1">
                                        Lance une procédure d'embauche pour Lancelot Dulac !
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing_pro.thread6.2">
                                        OK, je vais contacter Lancelot et récupérer ses pièces justificatives
                                        pour générer son contrat de travail et pré-remplir ses bulletins d'adhésion.
                                        <br/>S'il accepte votre offre, je créerais les rappels
                                        pour sa période d'essai et son rendez-vous médical.
                                    </Trans>
                                },
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing_pro.thread6.3">
                                        Génère une attestation employeur pour Marie !
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing_pro.thread6.4">
                                        C'est fait : <span className="highlight">Attestation-Marie-Soulot-mai-2020.pdf</span>.
                                        <br/>Souhaitez-vous que je lui partage ?
                                    </Trans>
                                },
                            ]}
                        />
                    </div>

                    <div id="submarine">
                        <img src={submarineImg} className="img-fluid"
                             alt={''}/>
                    </div>
                </div>
            </div>
            <div className="backscene">
                <img className="px" id="a-pro-1" style={getStyleObjectFromString("left: -65px;top: 300px;zoom: 40%")}
                     src={OctaveImg}
                     alt={''}/>
                <img className="px" id="a-pro-2"
                     style={getStyleObjectFromString("right:75px;top: 590px;zoom: 68%;transform: rotateZ(-62deg);")}
                     src={OctaveImg}
                     alt={''}/>
                <div className="container">
                    <img className="px" id="a-pro-3"
                         style={getStyleObjectFromString("top: 140px;left: 85px;transform: rotateZ(20deg); width:10%;")}
                         src={submarineImg}
                         alt={''}/>
                    <img style={getStyleObjectFromString("bottom: 0px;left:0px")} src={plant3Img}
                         alt={''}/>
                </div>
            </div>
        </section>
        <section id="demo">
            <div className="bg">
                <div className="container">
                    <div className="chat chat-center">
                        <ChatThread
                            messages={[
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing_pro.thread7.1">
                                        Comment faire pour t'adopter dans mon entreprise ?
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing_pro.thread7.2">
                                        Il suffit de me laisser votre e-mail :
                                    </Trans>
                                },
                            ]}
                        />
                    </div>
                    <div className="row">
                        <div className="col">
                            {landingForm}
                        </div>
                    </div>
                    <div className="brands row">
                        <div className="col-md">
                            <img src={BoxIcon} alt="Box" className="img-fluid"/>
                        </div>
                        <div className="col-md">
                            <img src={DropboxIcon} alt="Dropbox" className="img-fluid"/>
                        </div>
                        <div className="col-md">
                            <img src={ICloudIcon} alt="iCloud" className="img-fluid"/>
                        </div>
                        <div className="col-md">
                            <img src={GDriveIcon} alt="GoogleDrive" className="img-fluid"/>
                        </div>
                        <div className="col-md">
                            <img src={OneDriveIcon} alt="OneDrive" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="backscene">
                <img className="px" id="a-demo-1"
                     style={getStyleObjectFromString("left:16%;top: 500px;zoom: 40%;transform: scaleX(-1) rotateZ(-24deg)")}
                     src={fish4Img}
                     alt={''}/>
                <img className="px" id="a-demo-2"
                     style={getStyleObjectFromString("left: 10%;bottom: 24%;zoom: 24%;transform: scaleX(-1);")}
                     src={submarineImg}
                     alt={''}/>
                <img style={getStyleObjectFromString("bottom: -2px;right: 5%")} src={plant1Img}
                     alt={''}/>
                <div className="container">
                    <img className="px" id="a-demo-3"
                         style={getStyleObjectFromString("top: 450px;right: 80px;transform: rotateZ(-20deg); zoom:30%;")}
                         src={OctaveImg}
                         alt={''}/>
                </div>
            </div>
        </section>
        <section id="digicode">
            <div className="bg">
                <div className="container">
                    <div className="files brands">
                        <div className="col-md">
                            <img className="zip" src={zipIcon} alt="zip"/>
                        </div>
                        <div className="col-md">
                            <img className="txt" src={txtIcon} alt="txt"/>
                        </div>
                        <div className="col-md">
                            <img className="doc" src={docIcon} alt="doc"/>
                        </div>
                        <div className="col-md">
                            <img className="pdf" src={pdfIcon} alt="pdf"/>
                        </div>
                        <div className="col-md">
                            <img className="xls" src={xlsIcon} alt="xls"/>
                        </div>
                        <div className="col-md">
                            <img className="jpg" src={jpgIcon} alt="jpg"/>
                        </div>
                        <div className="col-md">
                            <img className="png" src={pngIcon} alt="png"/>
                        </div>
                    </div>

                    <p className="hook hook-center">
                        <Trans i18nKey="landing_pro.digicode.intro">
                            Les données de votre entreprise
                            <br/>toujours à jour !
                            <br/>
                            <small>Je récupère les documents depuis des centaines de services et maintiens les informations synchronisées.</small>
                        </Trans>
                    </p>
                    <div className="chat chat-center">
                        <div className="row">
                            <div className="col">
                                <div className="date">
                                    <Trans i18nKey="landing_pro.digicode.date">
                                        1 juin - 14h42
                                    </Trans>
                                </div>
                            </div>
                        </div>

                        <ChatThread
                            messages={[
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing_pro.thread8.1">
                                        Partage le nouveau mot de passe Wi-Fi du siège avec toute l'entreprise !
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing_pro.thread8.2">
                                        OK Marie. <span className="highlight">Code WiFi Siège</span> est désormais partagé avec 31 collaborateurs.
                                    </Trans>
                                },
                            ]}
                        />
                    </div>

                    <div className="iphone">
                        <img src={iphoneImg} className="img-fluid" alt="iphone-notification"/>
                    </div>
                </div>
            </div>

            <div className="backscene">
                <img className="px" id="a-digicode-1"
                     style={getStyleObjectFromString("left:16%;top: 500px;transform: rotateZ(24deg); zoom:30%;")}
                     src={fish1Img}
                     alt={''}/>
                <img style={getStyleObjectFromString("bottom: 0px;left: 5%")} src={plant2Img}
                     alt={''}/>
                <div className="container">
                    <img className="px" id="a-digicode-2"
                         style={getStyleObjectFromString("top:950px;left:-50px;transform: scaleX(-1); zoom:40%;")}
                         src={OctaveImg}
                         alt={''}/>
                    <img className="px" id="a-digicode-3"
                         style={getStyleObjectFromString("bottom:250px;right:150px;transform: scaleX(-1); zoom:60%;")}
                         src={fish3Img}
                         alt={''}/>
                </div>
            </div>
        </section>
        <section id="poll">
            <div className="bg">
                <div className="container">
                    <p className="hook hook-center">
                        <Trans i18nKey="landing_pro.poll.intro">
                            Je m'occupe de vos tâches sur mesure :
                        </Trans>
                    </p>
                    <div className="chat chat-center">
                        <ChatThread
                            messages={[
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing_pro.thread9.1">
                                        Génère une attestation employeur pour Lancelot Dulac !
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing_pro.thread9.2">
                                        C'est fait ✔️
                                    </Trans>
                                },
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing_pro.thread9.3">
                                        Déclare ma TVA du mois de juin !
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing_pro.thread9.4">
                                        J'ai calculé <span className="highlight"><b>842,20 €</b></span> de TVA déductible pour le mois de <b>juin 2020</b> avec <b>16</b> factures fournisseurs. <br/>Confirmez-vous la télé-déclaration ?
                                    </Trans>
                                },
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing_pro.thread9.5">
                                        C'est bon !
                                    </Trans>
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="backscene">
                <img className="px" id="a-poll-1" style={getStyleObjectFromString("left:6%;bottom: 100px; zoom:40%;")}
                     src={fish2Img}
                     alt={''}/>
                <img className="px" id="a-poll-2"
                     style={getStyleObjectFromString("top:450px;right:-50px;transform: scaleX(-1); zoom:40%;")}
                     src={OctaveImg}
                     alt={''}/>
                <div className="container">
                </div>
            </div>
        </section>
        <section id="footer">
            <div className="bg">
                <div className="container">
                    <p className="hook hook-center">
                        <Trans i18nKey="landing_pro.keep_contact.intro">
                            Ne perdez plus du temps
                            <br/>avec des tâches sans valeur ajoutée !
                            <br/>Confiez-les moi en me laissant votre e-mail :
                        </Trans>
                    </p>
                    <div className="row">
                        <div className="col">
                            {landingForm}
                        </div>
                    </div>

                    <p className="hook hook-center">
                        <Trans i18nKey="landing_pro.social.everywhere">
                            Je suis partout !
                        </Trans>
                    </p>

                    <div className="brands row">
                        <div className="col-md">
                            <a href="https://github.com/HeyOctave" target="_blank" rel="noreferrer">
                                <img src={githubImg} alt="Github" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md">
                            <a href="https://twitter.com/hey_octave" target="_blank" rel="noreferrer">
                                <img src={twitterImg} alt="Twitter" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md">
                            <a href="https://www.instagram.com/heyoctave" target="_blank" rel="noreferrer">
                                <img src={instagramImg} alt="Instagram" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md">
                            <a href="https://www.facebook.com/octaveai" target="_blank" rel="noreferrer">
                                <img src={facebookImg} alt="Facebook" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md">
                            <a href="https://www.linkedin.com/company/octaveai" target="_blank" rel="noreferrer">
                                <img src={linkedinImg} alt="LinkedIn" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md">
                            <a href="https://blog.hey-octave.com" target="_blank" rel="noreferrer">
                                <img src={PFictionImg} alt="Blog Poulpe Fiction" className="img-fluid"/>
                            </a>
                        </div>
                    </div>

                    <div className="GDPR-img">
                        <img src={rgpdImg} className="img-fluid" alt="RGPD"/>
                    </div>
                    <p className="hook hook-center">
                        <Trans i18nKey="landing_pro.security.intro">
                            Hey Octave est une solution hébergée en France où les données sont <b>chiffrées</b>.
                            <br/>
                            <small>
                                Vos données ne sont lisibles que par vous-même, avec autant de sécurité qu'un gestionnaire de mot de passe.
                            </small>
                        </Trans>
                    </p>

                    <div className="chat chat-center">
                        <ChatThread
                            messages={[
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing_pro.thread10.1">
                                        Comment aides-tu mon entreprise à mieux respecter le <b>RGPD</b> ?
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing_pro.thread10.2">
                                        Je tiens à jour le registre des activités de traitement des données internes de votre entreprise,
                                        conformément à l'article 30 du RGPD.
                                        <br/>J'identifie les parties prenantes, les catégories de données traitées,
                                        dans quels buts elles sont exploitées, qui peut y accèder,
                                        auprès de qui elles ont été communiquées et combien de temps vous pouvez les conserver.
                                    </Trans>
                                },
                            ]}
                        />
                    </div>

                    <Footer withOctave={true}/>
                </div>
            </div>

            <div className="backscene">
                <img className="px" id="a-footer-1"
                     style={getStyleObjectFromString("left:3%;top:600px; zoom:40%;transform: scaleX(-1) rotateZ(10deg)")}
                     src={submarineImg}
                     alt={''}/>
                <div className="container">
                </div>
            </div>
        </section>
    </>
}
