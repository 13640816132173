export async function loadScript(url, options = {}) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        script.src = url;
        if (options.loadIntegrity) {
            script.integrity = options.loadIntegrity;
        }
        script.crossOrigin = 'anonymous';
        window.document.getElementsByTagName('head')[0].appendChild(script);
    });
}
