import React, {useEffect, useState} from "react";
import {getStyleObjectFromString} from "../styleUtils";
import OctaveImg from '../images/octave.png';
import zipIcon from '../images/icon-zip.svg';
import jpgIcon from '../images/icon-jpg.svg';
import docIcon from '../images/icon-doc.svg';
import pngIcon from '../images/icon-png.svg';
import xlsIcon from '../images/icon-xls.svg';
import pdfIcon from '../images/icon-pdf.svg';
import BoxIcon from '../images/icon-box.png';
import txtIcon from '../images/icon-txt.svg';
import DropboxIcon from '../images/icon-dropbox.png';
import ICloudIcon from '../images/icon-icloud.png';
import GDriveIcon from '../images/logo-googledrive@1x.png';
import OneDriveIcon from '../images/logo-onedrive@1x.png';
import submarineImg from '../images/submarine.png';
import plant1Img from '../images/img-plant1.png';
import plant2Img from '../images/img-plant2.png';
import plant3Img from '../images/img-plant3.png';
import fish1Img from '../images/img-fish1.png';
import fish2Img from '../images/img-fish2.png';
import fish3Img from '../images/img-fish3.png';
import fish4Img from '../images/img-fish4.png';
import PFictionImg from '../images/poulpe-fiction.svg';
import rgpdImg from '../images/logo-rdpg.png';
import iphoneImg from '../images/iphone.png';
import facebookImg from '../images/social-facebook.svg';
import githubImg from '../images/social-github.svg';
import instagramImg from '../images/social-instagram.svg';
import linkedinImg from '../images/social-linkedin.svg';
import twitterImg from '../images/social-twitter.svg';
import {initScrollMagic} from "../lib/anim";
import LandingEmailForm from "../components/LandingEmailForm";
import {Carousel} from 'react-responsive-carousel';
import Navbar from "../components/Navbar";
import {Trans} from "react-i18next";
import Footer from "../components/Footer";
import ChatThread from "../components/ChatThread";

export default function LandingPersonalPage() {
    useEffect(() => {
        return initScrollMagic();
    }, []);

    const [landingSubmitted, setLandingSubmitted] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [pro, setPro] = useState<boolean>(false);

    const landingForm = <LandingEmailForm
        onSubmit={() => setLandingSubmitted(true)}
        submitted={landingSubmitted}
        email={email}
        setEmail={setEmail}
        pro={pro}
        setPro={setPro}
    />

    return <>
        <section id="intro" className={'first'}>
            <div className="bg">
                <div className="container">
                    <Navbar/>

                    <div className="row">
                        <div className="col col-md-3">
                            <img id="main-octave"
                                 className="push-sm-bottom img-fluid mx-auto d-block"
                                 alt={''}
                                 src={OctaveImg}/>
                        </div>
                        <div className="col-md">
                            <p className="hook">
                                <Trans i18nKey="landing.intro">
                                    Enchanté de faire votre connaissance,
                                    <br/>Je suis... <b>Octave</b>, le robot intelligent qui <b>comprend</b> et
                                    fait <b>voyager</b> vos documents
                                </Trans>
                            </p>

                            <div className="slider">
                                <Carousel
                                    showArrows={false}
                                    showStatus={false}
                                    showThumbs={false}
                                    emulateTouch={true}
                                    renderItem={(item, options) => {
                                        return <div
                                            className={`chat ${options?.isSelected || options?.isPrevious ? 'chat-active' : ''}`}>
                                            {item}
                                        </div>
                                    }}
                                >
                                    <ChatThread
                                        messages={[
                                            {
                                                from: 'marie',
                                                message: <Trans i18nKey="landing.thread1.1">
                                                    Octave, donne mon RIB à Papa !
                                                </Trans>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing.thread1.2">
                                                    Très bien, je m'en charge.
                                                </Trans>
                                            },
                                            {
                                                from: 'dad',
                                                message: <Trans i18nKey="landing.thread1.3">
                                                    Bien reçu !
                                                </Trans>
                                            },
                                        ]}
                                    />
                                    <ChatThread
                                        messages={[
                                            {
                                                from: 'marie',
                                                message: <div className="uploaded-file">
                                                    <img className="f-icon" src={pdfIcon} alt="pdf"/>
                                                    <span className="f-filename">
                                                        <Trans i18nKey="landing.thread2.1">
                                                        contrat-de-location.pdf
                                                        </Trans>
                                                    </span>
                                                </div>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing.thread2.2">
                                                    J'archive ce document
                                                </Trans>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing.thread2.3">
                                                    J'ai reconnu un contrat bail <small>(rue Cler, Paris au 16 février
                                                    2019)</small>.
                                                    <br/>Demandez-le moi plus tard, je vous le ressortirai !
                                                </Trans>
                                            },
                                        ]}
                                    />

                                    <ChatThread
                                        messages={[
                                            {
                                                from: 'marie',
                                                message: <Trans i18nKey="landing.thread3.1">
                                                    Envoie ma dernière facture EDF à Orange !
                                                </Trans>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing.thread3.2">
                                                    C'est fait. Autre chose ?
                                                </Trans>
                                            },
                                            {
                                                from: 'orange',
                                                message: <Trans i18nKey="landing.thread3.3">
                                                    Nous accusons réception de votre justificatif de domicile.
                                                    <br/>Votre service client Orange.
                                                </Trans>
                                            },
                                        ]}
                                    />

                                    <ChatThread
                                        messages={[
                                            {
                                                from: 'antoine',
                                                message: <Trans i18nKey="landing.thread4.1">
                                                    Hey Marie ! Je vais prendre les billets d'avion pour la Grèce.
                                                    Quel est ton n° de passeport ?
                                                </Trans>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing.thread4.2">
                                                    Partager <span className="highlight">numéro de passeport</span> avec <span className="highlight">Antoine</span> ?
                                                </Trans>
                                            },
                                            {
                                                from: 'marie',
                                                message: <Trans i18nKey="landing.thread4.3">
                                                    Oui
                                                </Trans>
                                            },
                                        ]}
                                    />

                                    <ChatThread
                                        messages={[
                                            {
                                                from: 'marie',
                                                message: <Trans i18nKey="landing.thread5.1">
                                                    Crée un dossier "Recherche appart" avec ma carte d'identité,
                                                    mes 3 derniers bulletins de paie
                                                </Trans>
                                            },
                                            {
                                                from: 'octave',
                                                message: <Trans i18nKey="landing.thread5.2">
                                                    Je n'ai pas ton document <span className="highlight">carte d'identité</span>.
                                                    <br/>Envoie-le moi, je me charge de le stocker en sécurité.
                                                </Trans>
                                            },
                                        ]}
                                    />
                                </Carousel>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="backscene">
                <div className="container">
                    <img className="px" id="a-intro-1"
                         style={{
                             bottom: -50,
                             zoom: '47%',
                             transform: 'scaleX(-1)',
                         }}
                         src={OctaveImg}
                         alt={''}/>
                    <img className="px" id="a-intro-2"
                         style={{
                             top: 446,
                             right: -150,
                             zoom: '25%',
                             transform: 'rotateZ(276deg)',
                         }}
                         src={OctaveImg}
                         alt={''}/>
                    <img className="px" id="a-intro-3"
                         style={{
                             bottom: 10,
                             right: -50,
                             zoom: '80%',
                             transform: 'scaleX(-1) rotateZ(-25deg)',
                         }}
                         src={OctaveImg}
                         alt={''}/>
                </div>
            </div>
        </section>
        <section id="pro">
            <div className="bg">
                <div className="container">
                    <p className="hook hook-center">
                        <Trans i18nKey="landing.pro.intro">
                            Je <b>simplifie</b> aussi
                            <br/>ta <b>vie</b> en <b>entreprise</b>
                        </Trans>
                    </p>
                    <div className="chat chat-center">
                        <ChatThread
                            messages={[
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing.thread6.1">
                                        Au fait, tu sais combien il me reste de jours de congé ?
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing.thread6.2">
                                        D'après <span className="highlight">BulletinSalaire-Mars2019.pdf</span>,
                                        il te reste 8 jours de congé et 2 jours de RTT.
                                    </Trans>
                                },
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing.thread6.3">
                                        Partage la nouvelle plaquette à l'équipe commerciale !
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing.thread6.4">
                                        Je partage <span className="highlight">Plaquette_Com_2019.pdf</span> <small>(GoogleDrive/MKTG/Présentations)</small>, avec <span className="highlight">Equipe Marketing</span>.
                                    </Trans>
                                },
                            ]}
                        />
                    </div>

                    <div id="submarine">
                        <img src={submarineImg} className="img-fluid"
                             alt={''}/>
                    </div>
                </div>
            </div>
            <div className="backscene">
                <img className="px" id="a-pro-1" style={getStyleObjectFromString("left: -65px;top: 300px;zoom: 40%")}
                     src={OctaveImg}
                     alt={''}/>
                <img className="px" id="a-pro-2"
                     style={getStyleObjectFromString("right:75px;top: 590px;zoom: 68%;transform: rotateZ(-62deg);")}
                     src={OctaveImg}
                     alt={''}/>
                <div className="container">
                    <img className="px" id="a-pro-3"
                         style={getStyleObjectFromString("top: 140px;left: 85px;transform: rotateZ(20deg); width:10%;")}
                         src={submarineImg}
                         alt={''}/>
                    <img style={getStyleObjectFromString("bottom: 0px;left:0px")} src={plant3Img}
                         alt={''}/>
                </div>
            </div>
        </section>
        <section id="demo">
            <div className="bg">
                <div className="container">
                    <div className="chat chat-center">
                        <ChatThread
                            messages={[
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing.thread7.1">
                                        Génial !!! Mais quand seras-tu disponible ?
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing.thread7.2">
                                        Inscris-toi et tu seras peut-être sélectionné(e) pour me tester
                                        en <b>avant-première</b> !
                                    </Trans>
                                },
                            ]}
                        />
                    </div>
                    <div className="row">
                        <div className="col">
                            {landingForm}
                        </div>
                    </div>
                    <div className="brands row">
                        <div className="col-md">
                            <img src={BoxIcon} alt="Box" className="img-fluid"/>
                        </div>
                        <div className="col-md">
                            <img src={DropboxIcon} alt="Dropbox" className="img-fluid"/>
                        </div>
                        <div className="col-md">
                            <img src={ICloudIcon} alt="iCloud" className="img-fluid"/>
                        </div>
                        <div className="col-md">
                            <img src={GDriveIcon} alt="GoogleDrive" className="img-fluid"/>
                        </div>
                        <div className="col-md">
                            <img src={OneDriveIcon} alt="OneDrive" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="backscene">
                <img className="px" id="a-demo-1"
                     style={getStyleObjectFromString("left:16%;top: 500px;zoom: 40%;transform: scaleX(-1) rotateZ(-24deg)")}
                     src={fish4Img}
                     alt={''}/>
                <img className="px" id="a-demo-2"
                     style={getStyleObjectFromString("left: 10%;bottom: 24%;zoom: 24%;transform: scaleX(-1);")}
                     src={submarineImg}
                     alt={''}/>
                <img style={getStyleObjectFromString("bottom: -2px;right: 5%")} src={plant1Img}
                     alt={''}/>
                <div className="container">
                    <img className="px" id="a-demo-3"
                         style={getStyleObjectFromString("top: 450px;right: 80px;transform: rotateZ(-20deg); zoom:30%;")}
                         src={OctaveImg}
                         alt={''}/>
                </div>
            </div>
        </section>
        <section id="digicode">
            <div className="bg">
                <div className="container">
                    <div className="files brands">
                        <div className="col-md">
                            <img className="zip" src={zipIcon} alt="zip"/>
                        </div>
                        <div className="col-md">
                            <img className="txt" src={txtIcon} alt="txt"/>
                        </div>
                        <div className="col-md">
                            <img className="doc" src={docIcon} alt="doc"/>
                        </div>
                        <div className="col-md">
                            <img className="pdf" src={pdfIcon} alt="pdf"/>
                        </div>
                        <div className="col-md">
                            <img className="xls" src={xlsIcon} alt="xls"/>
                        </div>
                        <div className="col-md">
                            <img className="jpg" src={jpgIcon} alt="jpg"/>
                        </div>
                        <div className="col-md">
                            <img className="png" src={pngIcon} alt="png"/>
                        </div>
                    </div>

                    <p className="hook hook-center">
                        <Trans i18nKey="landing.digicode.intro">
                            Partage comme tu en as envie,
                            <br/>j'interviens quand il y a besoin
                        </Trans>
                    </p>
                    <div className="chat chat-center">
                        <div className="row">
                            <div className="col">
                                <div className="date">
                                    <Trans i18nKey="landing.digicode.date">
                                        21 janv. - 14h32
                                    </Trans>
                                </div>
                            </div>
                        </div>

                        <ChatThread
                            messages={[
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing.thread8.1">
                                        Partage mon code d'immeuble avec mes invités de la raclette party !
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing.thread8.2">
                                        OK Marie. <span className="highlight">Code maison</span> est désormais partagé
                                        avec{' '}
                                        <b>Arthur</b>,{' '}
                                        <b>Charlotte</b>,{' '}
                                        <b>Léa</b> et{' '}
                                        <b>Thomas</b>.
                                    </Trans>
                                },
                            ]}
                        />
                    </div>

                    <div className="iphone">
                        <img src={iphoneImg} className="img-fluid" alt="iphone-notification"/>
                    </div>
                </div>
            </div>

            <div className="backscene">
                <img className="px" id="a-digicode-1"
                     style={getStyleObjectFromString("left:16%;top: 500px;transform: rotateZ(24deg); zoom:30%;")}
                     src={fish1Img}
                     alt={''}/>
                <img style={getStyleObjectFromString("bottom: 0px;left: 5%")} src={plant2Img}
                     alt={''}/>
                <div className="container">
                    <img className="px" id="a-digicode-2"
                         style={getStyleObjectFromString("top:950px;left:-50px;transform: scaleX(-1); zoom:40%;")}
                         src={OctaveImg}
                         alt={''}/>
                    <img className="px" id="a-digicode-3"
                         style={getStyleObjectFromString("bottom:250px;right:150px;transform: scaleX(-1); zoom:60%;")}
                         src={fish3Img}
                         alt={''}/>
                </div>
            </div>
        </section>
        <section id="poll">
            <div className="bg">
                <div className="container">
                    <p className="hook hook-center">
                        <Trans i18nKey="landing.poll.intro">
                            Je peux effectuer certaines démarches pour toi
                        </Trans>
                    </p>
                    <div className="chat chat-center">
                        <ChatThread
                            messages={[
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing.thread9.1">
                                        Combien d'heures la femme de ménage a-t-elle travaillé cette semaine ?
                                    </Trans>
                                },
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing.thread9.2">
                                        2 heures
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing.thread9.3">
                                        OK. Je compte donc 8h pour ce mois-ci.
                                        <br/>J'effectue le virement de 96 € (12€/heure) et je déclare ce montant au
                                        CESU.
                                        <br/>Est-ce que tu confirmes ?
                                    </Trans>
                                },
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing.thread9.4">
                                        Oui, merci !
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing.thread9.5">
                                        C'est fait
                                    </Trans>
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="backscene">
                <img className="px" id="a-poll-1" style={getStyleObjectFromString("left:6%;bottom: 100px; zoom:40%;")}
                     src={fish2Img}
                     alt={''}/>
                <img className="px" id="a-poll-2"
                     style={getStyleObjectFromString("top:450px;right:-50px;transform: scaleX(-1); zoom:40%;")}
                     src={OctaveImg}
                     alt={''}/>
                <div className="container">
                </div>
            </div>
        </section>
        <section id="footer">
            <div className="bg">
                <div className="container">
                    <p className="hook hook-center">
                        <Trans i18nKey="landing.keep_contact.intro">
                            Restons en contact !
                            <br/>Pas besoin de ton 06, juste de ton e-mail
                        </Trans>
                    </p>
                    <div className="row">
                        <div className="col">
                            {landingForm}
                        </div>
                    </div>

                    <p className="hook hook-center">
                        <Trans i18nKey="landing.social.everywhere">
                            Je suis partout !
                        </Trans>
                    </p>

                    <div className="brands row">
                        <div className="col-md">
                            <a href="https://github.com/HeyOctave" target="_blank" rel="noreferrer">
                                <img src={githubImg} alt="Github" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md">
                            <a href="https://twitter.com/hey_octave" target="_blank" rel="noreferrer">
                                <img src={twitterImg} alt="Twitter" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md">
                            <a href="https://www.instagram.com/heyoctave" target="_blank" rel="noreferrer">
                                <img src={instagramImg} alt="Instagram" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md">
                            <a href="https://www.facebook.com/octaveai" target="_blank" rel="noreferrer">
                                <img src={facebookImg} alt="Facebook" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md">
                            <a href="https://www.linkedin.com/company/octaveai" target="_blank" rel="noreferrer">
                                <img src={linkedinImg} alt="LinkedIn" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md">
                            <a href="https://blog.hey-octave.com" target="_blank" rel="noreferrer">
                                <img src={PFictionImg} alt="Blog Poulpe Fiction" className="img-fluid"/>
                            </a>
                        </div>
                    </div>

                    <div className="GDPR-img">
                        <img src={rgpdImg} className="img-fluid" alt="RGPD"/>
                    </div>
                    <p className="hook hook-center">
                        <Trans i18nKey="landing.security.intro">
                            Je prends ta <b>vie privée</b>
                            <br/>très au <b>sérieux</b>
                            <br/>
                            <small>
                                Tes données ne sont lisibles que par toi, avec autant de sécurité que
                                ton gestionnaire de mot de passe.
                            </small>
                        </Trans>
                    </p>

                    <div className="chat chat-center">
                        <ChatThread
                            messages={[
                                {
                                    from: 'marie',
                                    message: <Trans i18nKey="landing.thread10.1">
                                        Et le respect de mes données ?
                                    </Trans>
                                },
                                {
                                    from: 'octave',
                                    message: <Trans i18nKey="landing.thread10.2">
                                        Respectueux du droit et des libertés individuelles,
                                        je m'engage à respecter tes données personnelles conformément au RGPD
                                        entré en vigueur le 25 mai 2018.
                                    </Trans>
                                },
                            ]}
                        />
                    </div>

                    <Footer withOctave={true}/>
                </div>
            </div>

            <div className="backscene">
                <img className="px" id="a-footer-1"
                     style={getStyleObjectFromString("left:3%;top:600px; zoom:40%;transform: scaleX(-1) rotateZ(10deg)")}
                     src={submarineImg}
                     alt={''}/>
                <div className="container">
                </div>
            </div>
        </section>
    </>
}
