import React, {PureComponent} from 'react';
import OctaveLooking from "../components/OctaveLooking";
import {getCrackTimeDisplay, getStrengthResult, preload} from "../lib/passwordStrength";
import Layout from "../components/Layout";
import Footer from "../components/Footer";

const strengthsDisplay = {
    0: 'est beaucoup trop faible ☣',
    1: 'est trop faible ⚠',
    2: 'est passable 🌊 mais pas top ',
    3: 'a une bonne sécurité 🛡️',
    4: 'est fort 💪',
};

const strengthsColors = {
    'none': '#483AC5',
    0: 'red',
    1: '#FE4E68',
    2: '#ff833c',
    3: '#507CE7',
    4: '#4EE3C1',
};

export default class PasswordStrengthPage extends PureComponent {
    static propTypes = {};

    state = {
        passwordFocus: false,
        strength: null,
    };

    componentDidMount() {
        preload();
        window.document.body.style = `background-color: ${strengthsColors['none']}`;
        this.updateBodyStyle('none');
    }

    updateBodyStyle(score) {
        window.document.body.style = `transition: background-color .7s ease-in-out; background-color: ${strengthsColors[score]}`;
    }

    componentWillUnmount() {
        window.document.body.style = '';
    }

    onChange = (e) => {
        const value = e.target.value;
        if (!value) {
            this.updateBodyStyle('none');
            this.setState({strength: null});
            return;
        }

        const r = getStrengthResult(value);

        this.updateBodyStyle(r.score);
        this.setState({strength: r});
    }

    onFocus = () => {
        this.setState({passwordFocus: true});
    }

    onBlur = () => {
        this.setState({passwordFocus: false});
    }

    render() {
        const {passwordFocus, strength} = this.state;

        return <Layout id={'password-strength'}>
            <div className="strength-container">
                <div className="p-strength">
                    <OctaveLooking
                        passwordFocus={passwordFocus}
                    />
                    <div style={{
                        position: 'relative',
                        zIndex: 1,
                    }}>
                        <input
                            className={'form-control'}
                            id={'password'}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onChange={this.onChange}
                            autoComplete="new-password"
                            type="password"
                            placeholder={'Saisissez un mot de passe...'}
                        />
                        <div className="result">
                            {strength ? this.renderStrength() : <div>
                                Saissisez votre mot de passe pour savoir s'il est robuste
                            </div>}
                        </div>
                    </div>
                </div>
                <div>
                    <p>Les mots de passe ne sont pas collectés et restent chez vous.</p>
                    <p>
                        ➡{' '}<a href="https://blog.hey-octave.com/mot-de-passe-ultra-robuste/">Comment créer son mot de
                        passe robuste ?</a>
                    </p>
                </div>
            </div>
            <div className="container">
                <Footer/>
            </div>
        </Layout>
    }

    renderStrength() {
        const {strength} = this.state;

        return <div>
            <div className={'ps-strength'}>
                Votre mot de passe {' '}<b>{strengthsDisplay[strength.score]}</b>
            </div>
            <div>
                <div className={'online-attack'}>
                    Il faudrait <b>{getCrackTimeDisplay(strength, true)}</b> à un pirate pour le trouver en attaquant le
                    service
                </div>
                <div className={'offline-attack'}>
                    ou <b>{getCrackTimeDisplay(strength)}</b> pour le cracker s'il arrivait à s'introduire sur le
                    serveur.
                </div>
            </div>
        </div>
    }
}
