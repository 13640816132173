import React from 'react';
import {Link, LinkProps} from "react-router-dom";
import i18next from "i18next";

export default function LocaleLink(props: LinkProps) {
    const currentLanguage = i18next.language;

    return <Link
        {...props}
        to={`/${currentLanguage}${props.to}`}
    />
}
