import React from "react";
import LanguageSwitch from "./LanguageSwitch";
import LocaleLink from "../i18n/LocaleLink";
import {Trans} from "react-i18next";

type Props = {
    rootUrl?: string;
}

export default function Navbar({rootUrl = '/'}: Props) {
    return <nav className="navbar navbar-expand-lg">
        <LocaleLink
            className="navbar-brand"
            to={rootUrl}
        ><h1>Hey Octave</h1></LocaleLink>
        <div id="main-cta">
        </div>
        <button className="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" href="https://blog.hey-octave.com">
                        <Trans i18nKey="header.links.blog">
                            Blog
                        </Trans>
                    </a>
                </li>
                <li>
                    <span className="nav-link">•</span>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="https://app.hey-octave.com">
                        <Trans i18nKey="header.links.app">
                        Application
                        </Trans>
                    </a>
                </li>
                <li>
                    <span className="nav-link">•</span>
                </li>
                <li>
                    <LanguageSwitch id={'navbar'}/>
                </li>
            </ul>
        </div>
    </nav>
}
