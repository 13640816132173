import React from "react";
import LocaleLink from "../i18n/LocaleLink";
import LanguageSwitch from "./LanguageSwitch";
import {Trans} from "react-i18next";

type Props = {
    withOctave?: boolean;
}

export default function Footer({withOctave = false}: Props) {
    return <footer>
        <div className="row">
            <div className="col-sm">
                <ul className="footer-links">
                    <LanguageSwitch id={'footer'}/>
                    <li>
                        <a href={'https://www.google.com/maps/place/Aquarium+de+Paris/@48.8622162,2.2909927,15z/data=!4m2!3m1!1s0x0:0xf88aec5c3eb444e8?sa=X&ved=2ahUKEwiE_7HvzKr0AhWFgc4BHTRCBYkQ_BJ6BAhHEAU'}
                           rel={'noreferrer'}
                        >
                            Paris, France
                        </a>
                    </li>
                </ul>
            </div>
            <div className="col-sm">
                {withOctave && <div id="octave-footer"/>}
            </div>
            <div className="col-sm" id="stores">
                <ul className="footer-links">
                    <li><a href="https://blog.hey-octave.com/mentions-legales/">
                        <Trans i18nKey="footer.terms">
                            Mentions légales
                        </Trans>
                    </a>
                    </li>
                    <li>
                        {/*eslint-disable-next-line*/}
                        <a href="https://app.hey-octave.com" target="_blank">
                            <Trans i18nKey="footer.connect_to_app">
                                Se connecter à l'application
                            </Trans>
                        </a>
                    </li>
                    <li>
                        <LocaleLink
                            to={`/password-strength`}
                        >
                            <Trans i18nKey="footer.password_strength">
                                Est-ce que mon mot de passe est solide ?
                            </Trans>
                        </LocaleLink>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
}
