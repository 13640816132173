import React from "react"
import {useLocation} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {supportedLocales} from "../i18n/resources";
import i18next from "i18next";

type Props = {
    id: string;
}

export default function LanguageSwitch({id}: Props) {
    const location = useLocation();
    const i18n = useTranslation();

    const pageName = location.pathname;

    return <Dropdown>
        <Dropdown.Toggle variant="" id={`locale-switch-${id}`}>
            {i18n.t('lang')}
        </Dropdown.Toggle>

        <Dropdown.Menu
            align={ 'right'}
        >
            {supportedLocales.map((l: string) => <Link
                onClick={() => {
                    i18next.changeLanguage(l);
                }}
                key={l}
                to={pageName.replace(/^\/[^/]+\//, `/${l}/`)}>
                <Dropdown.Item
                    as={'div'}
                >
                    {i18n.t('lang', {
                        lng: l,
                    })}
                </Dropdown.Item>
            </Link>)}
        </Dropdown.Menu>
    </Dropdown>
}
