import OctavePicImg from "../images/userpic-octave.png";
import MarieImg from "../images/userpic-marie.png";
import React, {ReactNode} from "react";
import DadPicImg from "../images/userpic-dad.png";
import OrangePicImg from "../images/userpic-orange.png";
import AntoinePicImg from "../images/userpic-antoine.jpg";

type Message = {
    from: string;
    message: ReactNode;
}

type Props = {
    messages: Message[];
}

type Participant = {
    name: string;
    image: string;
}

const participants: Record<string, Participant> = {
    octave: {
        name: 'Octave',
        image: OctavePicImg,
    },
    antoine: {
        name: 'Antoine',
        image: AntoinePicImg,
    },
    marie: {
        name: 'Marie',
        image: MarieImg,
    },
    dad: {
        name: 'Dad',
        image: DadPicImg,
    },
    orange: {
        name: 'Orange',
        image: OrangePicImg,
    }
}

export default function ChatThread({messages}: Props) {
    return <>{messages.map((m, i) => {
        const isOctave = m.from === 'octave';
        const p = participants[m.from];

        return <div
            key={i}
            className={`row ${isOctave ? 'flex-row-reverse' : ''}`}>
            <div className="author">
                <img className="img-fluid" src={p.image} alt={p.name}/>
            </div>
            <div className={`bubble ${isOctave ? 'b-plain' : ''}`}>
                {m.message}
            </div>
        </div>
    })}</>
}
