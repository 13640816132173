import React from 'react';
import starfishLeft from "../images/eye-left-starfish.png";
import starfishRight from "../images/eye-right-starfish.png";
import eyeLeft from "../images/eye-left.svg";
import eyeRight from "../images/eye-right.svg";
import classNames from 'classnames';
import octaveImg from "../images/octave-noeyes.png";

type Props = {
    passwordFocus: boolean;
}

export default function OctaveLooking({passwordFocus}: Props) {
    return <div className={classNames({
        'octave': true,
        'password-focus': passwordFocus,
    })}>
        <div>
            <img className={'octave-img'} src={octaveImg} alt="Octave "/>
            <img className={'starfish sf-left'} src={starfishLeft} alt="starfish"/>
            <img className={'starfish sf-right'} src={starfishRight} alt="starfish"/>
            <img className={'eye e-left'} src={eyeLeft} alt="eye"/>
            <img className={'eye e-right'} src={eyeRight} alt="eye"/>
        </div>
    </div>
}
