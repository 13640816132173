import React, {FormEvent, useEffect, useState} from "react";
import {apiHost} from "../config";
import axios from "axios";
import {useTranslation} from "react-i18next";

const grecaptcha = (window as any).grecaptcha;

type Props = {
    onSubmit: () => void;
    submitted: boolean;
    setPro?: (pro: boolean) => void;
    setEmail: (email: string) => void;
    email: string;
    pro: boolean;
}

export default function LandingEmailForm({
                                             onSubmit: parentOnSubmit,
                                             submitted: parentSubmitted,
                                             setPro,
                                             setEmail,
                                             email,
                                             pro,
                                         }: Props) {
    const [submitted, setSubmitted] = useState(parentSubmitted);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const i18n = useTranslation();

    useEffect(() => {
        setSubmitted(parentSubmitted);
    }, [parentSubmitted])

    if (submitted) {
        return <div className="form-success">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
            {i18n.t('form.confirmation_message')}
        </div>
    }

    const onSubmit = (e: FormEvent) => {
        if (error) {
            setError(undefined);
        }
        setSubmitting(true);
        e.preventDefault();

        grecaptcha.ready(function () {
            grecaptcha.execute('6LfVL90ZAAAAALDdF_-hRmeiVyENGYPBZtVUdbRL', {
                action: 'landing/email/submit'
            }).then(function (token: string) {
                axios.post(`https://${apiHost}/email`, {
                    email,
                    company: pro,
                    token,
                }).then(() => {
                    setSubmitted(true);
                    parentOnSubmit();
                    (window as any).fbq('track', 'CompleteRegistration');
                }).catch((err) => {
                    console.error(err);
                    setError(err);
                    setSubmitting(false);
                });
            });
        });
    }

    return <form
        onSubmit={onSubmit}
        className="form-100"
    >
        {setPro && <div className="form-group">
            <div className="custom-control custom-checkbox">
                <input
                    disabled={submitting}
                    checked={pro}
                    onChange={(e) => setPro!(e.target.checked)}
                    type="checkbox" className="custom-control-input" name="company"
                       id="company"/>
                <label className="custom-control-label" htmlFor="company">
                    {i18n.t('form.pro.label')}
                </label>
            </div>
        </div>}
        <div className="form-group">
            <label htmlFor="email" className="sr-only">{i18n.t('form.email.label')}</label>
            <input
                disabled={submitting}
                id="email" name="email" type="email" className="form-control"
                   aria-describedby={i18n.t('form.email.label')}
                   placeholder={i18n.t('form.email.placeholder')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                   required/>
        </div>
        {error && <div className="error">{error}</div>}
        <button
            disabled={submitting}
            type="submit" className="btn btn-danger">

            {i18n.t('form.submit.label')}
        </button>
        <div className="recaptcha-notice">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
    </form>
}
