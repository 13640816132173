import * as languages from './';

export const defaultNS = 'translations';

type Languages = Record<string, any>;

export const resources: Languages = {};
Object.keys(languages as Languages).forEach(l => {
    resources[l] = {
        [defaultNS]: (languages as Languages)[l],
    };
});

export const supportedLocales: string[] = Object.keys(languages);

export const fallbackLocale = supportedLocales[0];

