import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

function $(id) {
    return window.document.querySelector(id);
}
function $$(id) {
    return window.document.querySelectorAll(id);
}


let scrollMagicInitiated = false;

export function initScrollMagic() {
    if (!scrollMagicInitiated) {
        ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
    }
    scrollMagicInitiated = true;
    const controller = new ScrollMagic.Controller();

    const scenes = [];

    $$('.chat').forEach(c => {
        const section = c.closest('section');
        const id = section.getAttribute('id');
        if (id === 'intro') {
            return;
        }
        scenes.push(new ScrollMagic.Scene({
            triggerElement: '#' + id,
            triggerHook: 'onEnter',
            reverse: false,
        })
        .setClassToggle('#' + id + ' .chat', 'chat-active')
        .addTo(controller));
    });

    const config = {
        'intro': {
            '1': {
                scene: {
                    triggerHook: 'onLeave',
                },
                tween: {
                    x: '-180%', y: '-180%'
                }
            },
            '2': {
                scene: {
                    triggerHook: 'onLeave',
                },
                tween: {
                    x: '-80%', y: '-80%'
                }
            },
            '3': {
                scene: {
                    triggerHook: 'onLeave',
                },
                tween: {
                    y: '120%',
                    opacity: 0,
                }
            },
        },
        'pro': {
            '1': {
                tween: {
                    x: '180%', y: '200%',
                }
            },
            '2': {
                tween: {
                    x: '-80%', y: '-80%'
                }
            },
            '3': {
                tween: {
                    x: '-120%', y: '-80%'
                }
            },
        },
        'demo': {
            '1': {
                tween: {
                    x: '-180%', y: '-120%',
                }
            },
            '2': {
                tween: {
                    x: '280%', y: '-50%'
                }
            },
            '3': {
                tween: {
                    y: '-200%'
                }
            },
        },
        'digicode': {
            '1': {
                tween: {
                    x: '-100%'
                }
            },
            '2': {
                tween: {
                    x: '-50%', y: '-80%'
                }
            },
            '3': {
                scene: {
                    triggerHook: 'onLeave',
                },
                tween: {
                    x: '250%', y: '50%'
                }
            },
        },
        'poll': {
            '1': {
                scene: {
                    triggerHook: 'onCenter',
                },
                tween: {
                    css: {scaleX: '2', scaleY: '2'},
                }
            },
            '2': {
                tween: {
                    x: '-50%', y: '-80%'
                }
            },
        },
        'footer': {
            '1': {
                tween: {
                    x: '250%', y: '-100%',
                }
            },
        },
    };

    Object.keys(config).forEach(function (section) {
        if (!$('#'+section)) {
            return;
        }

        Object.keys(config[section]).forEach(function (img) {
            const conf = config[section][img];
            new ScrollMagic.Scene(Object.assign({
                triggerElement: '#' + section,
                duration: '200%',
                triggerHook: 'onEnter',
            }, conf.scene || {}))
                .setTween('#a-' + section + '-'+img, conf.tween)
                .addTo(controller);
        });
    });

    if ($('#digicode')) {
        new ScrollMagic.Scene({
            triggerElement: '#digicode .iphone',
            duration: '50%',
            triggerHook: 'onEnter',
        })
            .setTween('#digicode .iphone', {opacity: '1'})
            .addTo(controller);
    }

    new ScrollMagic.Scene({
        triggerElement: '#intro',
        duration: '50%',
        triggerHook: 'onLeave',
    })
        .setTween('#main-octave', {y: '300px', opacity: 0})
        .addTo(controller);

    return () => {
        controller.destroy(true);
        scenes.forEach(s => {
            s.destroy(true);
        })
    }
}
