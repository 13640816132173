import React, {PropsWithChildren} from "react";
import Navbar from "./Navbar";

type Props = PropsWithChildren<{
    id?: string;
}>;

export default function Layout({
    children,
                                   id = 'intro'
                               }: Props) {
    return <section id={id} className={'first'}>
        <div className="bg" style={{height:'100vh'}}>
            <div className="container">
                <Navbar />
            </div>
            {children}
        </div>
    </section>
}
