import {loadScript} from "./scriptLoader";

export async function preload() {
    await loadScript("https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js", {
        loadIntegrity: "sha256-Znf8FdJF85f1LV0JmPOob5qudSrns8pLPZ6qkd/+F0o=",
    });
}

export function getStrength(password, user_inputs) {
    if (!window.zxcvbn) {
        console.error(new Error(`Cannot load zxcvbn`));

        return 0;
    }

    const r = window.zxcvbn(password, user_inputs);
    return r.score;
}


export function getStrengthResult(password) {
    if (!window.zxcvbn) {
        console.error(new Error(`Cannot load zxcvbn`));
    }

    return window.zxcvbn(password);
}

export function getCrackTimeDisplay(result, online) {
    const s = result.crack_times_seconds[online ? 'online_no_throttling_10_per_second' : 'offline_slow_hashing_1e4_per_second'];

    const div = [
        1,
        60,
        60,
        24,
        30.5,
        12,
        1000000,
        1000,
        1000,
        1000,
        1000,
        1000,
        1000,
        1000,
        1000,
    ];
    const units = [
        'seconde[s]',
        'minute[s]',
        'heure[s]',
        'jour[s]',
        'mois',
        'an[s]',
        `million[s] d'années`,
        `milliard[s] d'années`,
        `trillion[s] d'années`,
        `quatrillion[s] d'années`,
        `quintillion[s] d'années`,
        `sextillion[s] d'années`,
        `septillion[s] d'années`,
        `octillion[s] d'années`,
        `nonillion[s] d'années`,
    ];

    let i = s;
    let d = 0;
    while (i > div[d] && d < div.length) {
        i = i / div[d++];
    }

    if (d === 0) {
        return `moins d'une seconde`;
    }

    i = Math.round(i);
    const u = units[d - 1].replace('[s]', i > 1 ? 's' : '');


    return `${i} ${u}`;
}
