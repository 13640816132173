import LandingPersonalPage from "../pages/LandingPersonalPage";
import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import NotFound from "../pages/NotFound";
import {RouteChildrenProps} from "react-router";
import PasswordStrengthPage from "../pages/PasswordStrengthPage";
import {supportedLocales} from "../i18n/resources";
import LandingProPage from "../pages/LandingProPage";

type Props = RouteChildrenProps;
type RouteParams = {
    lang: string;
};

export default function LocaleRouteProxy({match}: Props) {
    const lang: string = (match!.params as RouteParams).lang;

    return supportedLocales.includes(lang) ? <Switch>
            <Route path="/:lang/" exact component={LandingProPage} />
            <Route path="/:lang/personal" component={LandingPersonalPage} />
            <Route path="/:lang/password-strength" component={PasswordStrengthPage} />
            <Route path="/:lang/pro" render={() => <Redirect to={'/'} />} />
            <Route path="/:lang/" component={NotFound} />
        </Switch> : <Redirect to={`/`} />
}
