import i18n, {InitOptions} from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {defaultNS, resources, supportedLocales} from './resources';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        defaultNS,
        supportedLngs: supportedLocales,
        fallbackLng: "fr",
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['path', 'navigator'],
        }
    } as InitOptions);
